import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import SubscriptionApiService from "@/core/services/api.service.subscription";
import moment from "moment";


export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },

    name: "shop",
    data() {
        return {
            totalSubscriptions: 0,
            subscriptions: [],
            loading: true,
            pagination: {},
            headers: [
                { text: "Id", align: "start", sortable: true, value: "id" },
                { text: "Created", value: "created", sortable: false },
                { text: "Active", value: "active", sortable: false },
                { text: "Subscription Id", value: "subscriptionId", sortable: false},
                { text: "Subscription Name", value: "subscriptionName", sortable: false},
                { text: "Interval", value: "subscriptionTimeIntervalType", sortable: false},
                { text: "AutoRenewal", value: "autoRenewal", sortable: false},
                { text: "Price", value: "price", sortable: false},
                { text: "Last Payment Date", value: "lastPaymentDate", sortable: false},
                { text: "Invoice Status", value: "invoiceStatus", sortable: false},
                { text: "Invoice Url", value: "invoiceUrl", sortable: false}
            ],
            errors: []
        };
    },
    async created() {
        this.loading = true;
        this.shopId = this.$route.params.shopId;
        this.shopName = this.$route.params.shopName;
        this.$log.debug("shopid: ", this.shopId);
        this.$store.dispatch(SET_BREADCRUMB, [{title: "Details for shop " + this.shopName}]);
        this.$log.debug("await init sub service - start");
        await SubscriptionApiService.init();
        this.$log.debug("await init sub service - end");
        this.retrieveSubscriptions(this.shopId);
    },

    methods: {
        parseDate(timestamp) {
            this.$log.debug("timestamp:",timestamp);
            return moment
                .utc(timestamp)
                .local()
                .format("yyyy-MM-DD HH:mm:ss");
        },
        getRequestParams() {
            let params = {};

            let sort;
            let sortBy = this.pagination.sortBy;
            let sortDesc = this.pagination.sortDesc;
            if (sortBy.length === 1 && sortDesc.length === 1) {
                sort = sortBy[0];
                if (sortDesc[0] === true) sort += ",desc";
            }
            params["sort"] = sort;
            params["page"] = this.pagination.page;
            params["size"] = this.pagination.itemsPerPage;

            return params;
        },
        retrieveSubscriptions (shopId) {
            if (this.shopId != null) {
                const params = this.getRequestParams();
                this.$log.debug("params: ", params);
                SubscriptionApiService.query(`/GetMembershipFiscalizationsForShop/${shopId}`, params)
                    .then(response => {
                        // JSON responses are automatically parsed.
                        this.$log.debug("Subscriptions: ", response.data.content);
                        this.subscriptions = response.data.content;
                        this.totalSubscriptions = response.data.totalElements;
                        this.totalPages = response.data.totalPages;
                        this.loading = false;
                        let title =
                            this.totalSubscriptions != null
                                ? this.totalSubscriptions
                                : 0;
                        this.$store.dispatch(SET_BREADCRUMB, [{title: "Subscriptions for shop " + this.shopName + ": " + title}]);

                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => (this.loading = false));
            }
        },
        previousState() {
            this.$router.go(-1);
        },
    },
    components: {
        ConfirmDialog
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        }
    }
};
