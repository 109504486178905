var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header border-0 pt-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.subscriptions,"options":_vm.pagination,"server-items-length":_vm.totalSubscriptions,"loading":_vm.loading,"footer-props":{
      'showFirstLastPage':true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.created))+" ")]}},{key:"item.lastPaymentDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.lastPaymentDate))+" ")]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }